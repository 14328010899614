import { SVGProps, Suspense, lazy } from 'react'

type IconThemeProps = SVGProps<SVGSVGElement> & {
  icon: string
}

export const IconTheme = ({ icon, ...rest }: IconThemeProps) => {
  const Icon = icon ? lazy(() => import(`@interco/icons/${icon}/index.js`)) : null

  return <Suspense>{Icon ? <Icon color="var(--primary500)" {...rest} /> : null}</Suspense>
}
