import { useCallback, useState } from 'react'

import { Accordion as AccordionUI } from '@interco/inter-ui/components/Accordion'
import { interWbSession } from '@interco/inter-webview-bridge'

type AccordionProps = {
  title: string
  content: string
}

export const Accordion = ({ title, content }: AccordionProps) => {
  const [accordionsTitles, setAccordionsTitles] = useState<string[]>([])

  const handleAccordionClick = useCallback(
    (clickedTitle: string) => {
      interWbSession.requestAnalytics('FAQ_DUVIDA', {
        ref_figma: '68',
        flow: 'dados-bancarios',
        screen: 'dados-bancarios',
        content_name: title,
        correntista: 'true',
        product: 'global-account',
      })

      if (accordionsTitles.includes(title)) {
        return setAccordionsTitles(
          accordionsTitles.filter((actualTitle) => actualTitle !== clickedTitle),
        )
      }

      return setAccordionsTitles((prevState) => [...prevState, title])
    },
    [accordionsTitles, title],
  )

  return (
    <AccordionUI
      title={title}
      variant="containerized"
      expanded={accordionsTitles.includes(title)}
      onClick={() => handleAccordionClick(title)}
    >
      {content}
    </AccordionUI>
  )
}
