import { useAppInfo } from 'hooks/useAppInfo'
import { IconTheme } from 'components/IconTheme'
import { useGetTermsAndConditions } from 'services/terms-and-conditions/useTermsAndConditions'
import { handleBackButton, handleSelectTerm } from 'utils'
import { useNavigate } from 'react-router-dom'
import { GetTermsAndConditionsApiProps } from 'services/terms-and-conditions/types'
import terms from 'services/terms-and-conditions/data'
import { compare } from 'compare-versions'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Card } from '@interco/inter-ui/components/Card'
import { Text } from '@interco/inter-ui/components/Text'
import { Loading } from '@interco/inter-ui/components/Loading'

import { Container } from './styles'

export function TermsAndConditions() {
  const appInfo = useAppInfo()
  const { appVersion, language } = appInfo
  const selectedLanguage = language ?? 'en-US'
  const navigate = useNavigate()

  const { data: termsResponse } = useGetTermsAndConditions()
  const data =
    appVersion && compare(String(appVersion), '13.4', '>=') ? termsResponse : terms[language]

  const title: Record<string, string> = {
    'pt-BR': 'Termos e condições',
    'en-US': 'Terms and conditions',
    'es-ES': 'Términos y condiciones',
  }

  const handleSelectTermVersion = (term: GetTermsAndConditionsApiProps) => {
    if (compare(appVersion, '13.4', '>=')) {
      handleSelectTerm(term)
    } else {
      navigate(`/terms-and-conditions-view-document/${term.id}/is-webview`)
    }
  }

  return (
    <>
      <AppBar
        leftIcon={
          <IconTheme
            icon="orangeds/MD/arrow-left"
            width={24}
            height={24}
            onClick={handleBackButton}
          />
        }
      >
        {title[selectedLanguage]}
      </AppBar>
      {!data && (
        <Container>
          <Loading />
        </Container>
      )}
      <FlexColumn alignItems="center" justifyContent="center" className="p-4 gap-y-4">
        {!!data &&
          data
            .filter(
              (item) =>
                item.title !== null &&
                item.title !== 'null' &&
                item.description !== null &&
                item.description !== 'null' &&
                item.url !== null &&
                item.url !== 'null',
            )
            .map((item) => (
              <Card key={item.id} onClick={() => handleSelectTermVersion(item)}>
                <FlexRow alignItems="center" gap="16px">
                  <IconTheme
                    icon="orangeds/MD/pdf-file"
                    className="shrink-0"
                    width={24}
                    height={24}
                    color="var(--gray500)"
                  />
                  <FlexColumn gap="4px">
                    <Text variant="body-3" as="p" bold colorWeight={500}>
                      {item.title}
                    </Text>
                    <Text variant="caption-1" as="p">
                      {item.description}
                    </Text>
                  </FlexColumn>
                  <IconTheme
                    icon="orangeds/MD/chevron-right"
                    className="shrink-0"
                    width={24}
                    height={24}
                  />
                </FlexRow>
              </Card>
            ))}
      </FlexColumn>
    </>
  )
}
