import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  GetSettingsGaDebitCardApiProps,
  GetSettingsGaDebitCardProps,
  SetSettingsGaDebitCardProps,
} from './types'
import { getSettingsGaDebitCard, setSettingsGaDebitCard } from '.'

export const useGetSettingsGaDebitCard = (
  props: GetSettingsGaDebitCardProps,
  options?: UseQueryOptions<GetSettingsGaDebitCardApiProps[], Error>,
) =>
  useQuery<GetSettingsGaDebitCardApiProps[], Error>(
    ['get-settings-ga-debit-card', props],
    () => getSettingsGaDebitCard(props),
    {
      ...options,
    },
  )

export const useSetSettingsGaDebitCard = (
  options?: UseMutationOptions<void, Error, SetSettingsGaDebitCardProps>,
) =>
  useMutation(setSettingsGaDebitCard, {
    mutationKey: ['set-settings-ga-debit-card'],
    ...options,
  })
