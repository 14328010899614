import { useAppInfo } from 'hooks/useAppInfo'
import { data } from 'pages/AccountInfo/AccountInfoError/data'

import { FlexColumn } from '@interco/inter-ui/components/Flex'
import { Signal } from '@interco/inter-ui/components/Signal'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { interWbNavigate } from '@interco/inter-webview-bridge'
import Canceled from '@interco/icons/orangeds/MD/canceled'

type AccountInfoErrorProps = {
  refetch: () => void
}

export const AccountInfoError = ({ refetch }: AccountInfoErrorProps) => {
  const appInfo = useAppInfo()
  const selectedLanguage = appInfo?.language ?? 'pt-BR'

  const handleRetryButton = () => refetch()

  const handleBackButton = () => interWbNavigate.requestGoBack()

  return (
    <FlexColumn height="100vh">
      <FlexColumn alignItems="center" justifyContent="center" height="100%">
        <Signal icon={Canceled} theme="error" intensity="dark" />
        <Spacing mb="sm" />
        <Text variant="headline-h1" as="h1" semiBold>
          {data[selectedLanguage].title}
        </Text>
        <Spacing mb="xxs" />
        <Text variant="body-3" as="p">
          {data[selectedLanguage].description}
        </Text>
      </FlexColumn>
      <FlexColumn className="p-6" gap="1rem">
        <Button fullWidth onClick={handleRetryButton}>
          {data[selectedLanguage].primaryButton}
        </Button>
        <Button variant="secondary" fullWidth onClick={handleBackButton}>
          {data[selectedLanguage].secondaryButton}
        </Button>
      </FlexColumn>
    </FlexColumn>
  )
}
