type SeparatorProps = {
  variant: 'bar' | 'dashed' | 'line'
}
const separator = {
  bar: <div className="w-full h-2 my-8 bg-inter-gray-100" />,
  dashed: <div className="w-full border-t border-dashed border-inter-gray-200" />,
  line: <div className="w-full border-t border-solid border-inter-gray-200" />,
}

export const Separator = ({ variant }: SeparatorProps) => separator[variant]
