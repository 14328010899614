import { useCallback, useEffect, useState } from 'react'

import InterWebviewBridge, {
  IWbAppInfo,
  interWbSession,
  WbEnvironments,
} from '@interco/inter-webview-bridge'

import { appInfoMock } from './mockAppInfo'

export const useAppInfo = () => {
  const [appInfo, setAppInfo] = useState<IWbAppInfo>({} as IWbAppInfo)

  const interWb = InterWebviewBridge.getInstance()

  const getAppInfo = useCallback(async () => {
    if (interWb.getEnvironment() === WbEnvironments.BROWSER) {
      setAppInfo(appInfoMock)
    } else {
      const data = await interWbSession.getAppInfo()
      setAppInfo(data)
    }
  }, [interWb])

  useEffect(() => {
    getAppInfo()
  }, [getAppInfo])

  return appInfo
}
