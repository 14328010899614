import { Separator } from 'components/Separator'

import { FlexColumn } from '@interco/inter-ui/components/Flex'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Card } from '@interco/inter-ui/components/Card'

export const AccountInfoSkeleton = () => (
  <>
    <FlexColumn className="px-6 mt-4" gap="8px">
      <Skeleton width="200px" height="25px" />
      <Spacing mb="xxxs" />
      {Array.from(Array(2).keys()).map((index) => (
        <Card key={index}>
          <Skeleton width="60%" height="20px" />
          <Spacing mb="xxs" />
          <Separator variant="dashed" />
          <Spacing mb="xs" />
          <FlexColumn gap="8px">
            <Skeleton width="20%" height="15px" />
            <Skeleton width="40%" height="15px" />
          </FlexColumn>
        </Card>
      ))}
    </FlexColumn>
    <Separator variant="bar" />
    <FlexColumn className="px-6" gap="8px">
      <Skeleton width="100px" height="25px" />
      <Spacing mb="xxxs" />
      {Array.from(Array(4).keys()).map((index) => (
        <FlexColumn
          key={index}
          className="p-4 border border-solid border-inter-gray-200 rounded-lg"
        >
          <Skeleton width="60%" height="20px" />
        </FlexColumn>
      ))}
    </FlexColumn>
  </>
)
