import { GetTermsAndConditionsApiProps } from 'services/terms-and-conditions/types'

import { interWbHttp } from '@interco/inter-webview-bridge'

export const getTermsAndConditions = async (): Promise<GetTermsAndConditionsApiProps[]> => {
  const endpoint = 'app-pf/ia/cb-onboarding-bff/v1/terms'
  const { response } = await interWbHttp.get(endpoint)
  const data = JSON.parse(response)
  return data
}
