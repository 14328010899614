import { useGetAccountInfo } from 'services/account-info/useAccountInfo'
import { AccountInfoSkeleton } from 'pages/AccountInfo/AccountInfoSkeleton'
import { AccountInfoError } from 'pages/AccountInfo/AccountInfoError'
import { IconTheme } from 'components/IconTheme'
import { Card } from 'pages/AccountInfo/components/Card'
import { Accordion } from 'pages/AccountInfo/components/Accordion'
import { Alert } from 'pages/AccountInfo/components/Alert'
import { Separator } from 'components/Separator'

import { Text } from '@interco/inter-ui/components/Text'
import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Spacing } from '@interco/inter-ui/components/Spacing'

export const AccountInfo = () => {
  const { data, isFetching, isError, refetch } = useGetAccountInfo()

  return (
    <>
      {isFetching ? (
        <AccountInfoSkeleton />
      ) : (
        <>
          {isError ? (
            <AccountInfoError refetch={refetch} />
          ) : (
            <>
              {data?.tipoConta.map((conta) => (
                <FlexColumn key={conta.nome} className="px-6 mt-4" gap="8px">
                  <FlexRow gap="16px" justifyContent="flex-start">
                    <IconTheme
                      icon="orangeds/MD/eua"
                      width={20}
                      height={20}
                      color="var(--white100)"
                    />
                    <Text variant="headline-h3" as="h3" semiBold>
                      {conta.nome}
                    </Text>
                  </FlexRow>
                  {conta.informacoesConta.map((info) =>
                    info.avisos ? (
                      <Alert
                        key={info.avisos.titulo}
                        type="grayscale"
                        title={info.avisos.titulo}
                        content={info.avisos.mensagem}
                        customIcon={
                          <IconTheme
                            icon="orangeds/MD/globe"
                            width={16}
                            height={16}
                            color="var(--gray500)"
                          />
                        }
                      />
                    ) : (
                      <Card key={info.titulo} title={info.titulo} items={info.itens} />
                    ),
                  )}
                </FlexColumn>
              ))}
              <Separator variant="bar" />
              <FlexColumn className="px-6" gap="16px">
                <Text variant="headline-h3" as="h3" semiBold>
                  {data?.entenda.tituloEntenda}
                </Text>
                {data?.entenda.informacoes.map((info) => (
                  <Accordion key={info.title} title={info.title} content={info.message} />
                ))}
              </FlexColumn>
              <Spacing mb="xs" />
            </>
          )}
        </>
      )}
    </>
  )
}
