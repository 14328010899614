export const data: Record<
  string,
  { title: string; description: string; primaryButton: string; secondaryButton: string }
> = {
  'pt-BR': {
    title: 'Houve um erro por aqui',
    description: 'Você pode tentar novamente mais tarde.',
    primaryButton: 'Tentar novamente',
    secondaryButton: 'Voltar para a home',
  },
  'en-US': {
    title: 'There was an error around here',
    description: 'You can try again later',
    primaryButton: 'Try again',
    secondaryButton: 'Go back to the homepage',
  },
  'es-ES': {
    title: 'Hubo un error por aquí',
    description: 'Puedes intentarlo de nuevo más tarde',
    primaryButton: 'Volver a la página de inicio',
    secondaryButton: 'Intentar de nuevo',
  },
}
