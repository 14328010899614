import { useEffect } from 'react'

import { useAppInfo } from 'hooks/useAppInfo'
import { RouterProvider } from 'react-router-dom'
import { router } from 'routes'
import { ThemesEnum } from 'common/enums/themes'

import { initTheme, Theme } from '@interco/inter-ui'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

function App() {
  const appTheme = useAppInfo()?.application
  const isDarkMode = useAppInfo()?.isDarkMode

  useEffect(() => {
    initTheme(appTheme === ThemesEnum.PJ ? Theme.PJ : Theme.PF, isDarkMode)
  }, [appTheme, isDarkMode])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
