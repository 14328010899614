import { GetTermsAndConditionsApiProps } from 'services/terms-and-conditions/types'
import { GetTermsGlobalAccountApiProps } from 'services/terms-global-account/types'

import { interWbNavigate } from '@interco/inter-webview-bridge'

export const handleBackButton = () => interWbNavigate.requestGoBack()

export const handleSelectTerm = (
  item: GetTermsAndConditionsApiProps | GetTermsGlobalAccountApiProps,
) => {
  interWbNavigate.openPdf(item.title, item.url)
}

export const parseVersionToNumber = (version: string) =>
  version
    .split('.')
    .map((item) => Number(item))
    .reduce((value: number, sum: number) => value + sum, 0)
