import { GetTermsGlobalAccountApiProps } from 'services/terms-global-account/types'

const terms: Record<string, GetTermsGlobalAccountApiProps[]> = {
  'pt-BR': [
    {
      key: '',
      id: 'e-sign-consent-agreement',
      title: 'Acordo de consentimento para assinatura eletrônica',
      description:
        'O usuário concorda em receber comunicações eletrônicas da Inter&Co e utilizar assinaturas eletrônicas.',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-pt-BR.pdf',
    },
    {
      key: '',
      id: 'short-form',
      title: 'Inter Conta Global',
      description: 'Acesse informações sobre taxas de serviço',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-pt-BR.pdf',
    },
    {
      key: '',
      id: 'long-form',
      title: 'Cronograma de Taxas da Conta Global Inter',
      description: 'Acesse informações sobre taxas de serviço',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-pt-BR.pdf',
    },
    {
      key: '',
      id: 'user-agreement',
      title: 'Termo de Acordo do Usuário',
      description: 'Acesse o Contrato de Usuário para o uso do aplicativo e da conta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement-pt-BR.pdf',
    },
  ],
  'en-US': [
    {
      key: '',
      id: 'e-sign-consent-agreement',
      title: 'E-sign consent agreement',
      description:
        'Consent to use electronic signatures and receive electronic communications from Inter&Co.',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-en-US.pdf',
    },
    {
      key: '',
      id: 'short-form',
      title: 'inter&co Account | Short Form Disclosure',
      description: `Access information about service fees`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-en-US.pdf',
    },
    {
      key: '',
      id: 'long-form',
      title: 'inter&co Account Fees Schedule | Long Form',
      description: `Access information about service fees`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-en-US.pdf',
    },
    {
      key: '',
      id: 'user-agreement',
      title: 'User Agreement',
      description: `Access the User Agreement of the app's and account's usage`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement-en-US.pdf',
    },
  ],
  'es-ES': [
    {
      key: '',
      id: 'e-sign-consent-agreement',
      title: 'Acuerdo de consentimiento de Firma electrónica (E-Sign)',
      description:
        'Consienta a usar una firma electrónica y a recibir comunicaciones electrónicas de Inter&Co',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-es-ES.pdf',
    },
    {
      key: '',
      id: 'short-form',
      title: 'Inter Cuenta Global',
      description: 'Acceda a información sobre tarifas de servicio',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-es-ES.pdf',
    },
    {
      key: '',
      id: 'long-form',
      title: 'Programa de Tarifas de Cuenta Global Inter',
      description: 'Acceda a información sobre tarifas de servicio',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-es-ES.pdf',
    },
    {
      key: '',
      id: 'user-agreement',
      title: 'Acuerdo de Usuario',
      description: 'Acceda al Contrato de Usuario para el uso de la aplicación y de la cuenta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement-es-ES.pdf',
    },
    {
      key: '',
      id: 'global-account-agreement',
      title: 'Acordo de Cuenta Inter Global',
      description: 'Acceda a los términos de uso de la cuenta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/global-account-agreement-es-ES.pdf',
    },
  ],
}

export default terms
