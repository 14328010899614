import { IconTheme } from 'components/IconTheme'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { FlexColumn } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

type GaDebitCardInAnalysisProps = {
  handleBackButton: () => void
}

export const GaDebitCardInAnalysis = ({ handleBackButton }: GaDebitCardInAnalysisProps) => (
  <FlexColumn height="100vh" justifyContent="flex-start">
    <AppBar
      leftIcon={
        <IconTheme
          icon="orangeds/MD/arrow-left"
          width={24}
          height={24}
          onClick={handleBackButton}
        />
      }
    />
    <FlexColumn
      className="p-6"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="0.5rem"
    >
      <div className="flex items-center justify-center bg-[var(--alert500)] rounded-full size-16 mb-4 mt-auto">
        <IconTheme icon="orangeds/MD/pending" width={32} height={32} color="white" />
      </div>
      <Text variant="headline-h1" colorWeight={500} semiBold className="text-center">
        Configuração de débito em análise
      </Text>
      <Text variant="body-3" className="text-center">
        Enquanto analisamos sua solicitação, você consegue utilizar seu cartão de débito com a
        configuração anterior
      </Text>
      <FlexColumn className="mt-auto" gap="1.5rem">
        <Button fullWidth onClick={handleBackButton}>
          Entendi
        </Button>
      </FlexColumn>
    </FlexColumn>
  </FlexColumn>
)
