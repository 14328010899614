import { GetTermsGlobalAccountApiProps } from 'services/terms-global-account/types'

import { interWbHttp } from '@interco/inter-webview-bridge'
import InterWebviewBridge, { WbEnvironments } from '@interco/inter-webview-bridge'

import terms from './data'

export const getTermsGlobalAccount = async (): Promise<GetTermsGlobalAccountApiProps[]> => {
  const interWb = InterWebviewBridge.getInstance()

  if (interWb.getEnvironment() === WbEnvironments.BROWSER) {
    const data = terms['en-US']
    return data
  }
  const endpoint = '/app-pf/ia/global-account/v2/creation/account/terms'
  const { response } = await interWbHttp.get(endpoint)
  const data = JSON.parse(response)
  return data
}
