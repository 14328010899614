import { Alert as AlertUI, AlertProps as AlertUIProps } from '@interco/inter-ui/components/Alert'
import { interWbSession } from '@interco/inter-webview-bridge'

type AlertProps = AlertUIProps & {
  title: string
  content: string
}

export const Alert = ({ title, content, ...rest }: AlertProps) => {
  interWbSession.requestAnalytics('TRANSFERIR_GA_FLUXO', {
    ref_figma: '67',
    flow: 'dados-bancarios',
    screen: 'dados-bancarios',
    ref_type: 'message',
    content_action: 'alert',
    action_id: title,
    type: 'info',
  })

  return (
    <AlertUI title={title} {...rest}>
      {content}
    </AlertUI>
  )
}
