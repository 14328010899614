export const data: Record<
  string,
  {
    tag: string
    title: string
    stepOne: string
    stepTwo: string
    stepThree: string
    primaryButton: string
  }
> = {
  'pt-BR': {
    tag: 'Em Breve',
    title: 'Pagamentos em tempo real',
    stepOne: 'Faça pagamentos em segundos',
    stepTwo: 'Envie no dia e na hora que quiser',
    stepThree: 'Pague com segurança garantida',
    primaryButton: 'Entendi',
  },
  'en-US': {
    tag: 'Coming soon',
    title: 'Make payments in real time',
    stepOne: 'Send funds to recipients within seconds',
    stepTwo: 'Transfer money 24/7',
    stepThree: 'Make any payment with 100% safety',
    primaryButton: 'Got it',
  },
  'es-ES': {
    tag: 'En Breve',
    title: 'Realiza pagos en tiempo real',
    stepOne: 'Envía fondos en cuestión de segundos',
    stepTwo: 'Transfiere dinero las 24 horas del día',
    stepThree: 'Seguridad en todas tus transacciones',
    primaryButton: 'Entendí',
  },
}
