import styled from 'styled-components'
import { Document as DocumentPDF } from 'react-pdf'

export const Document = styled(DocumentPDF)`
  overflow: hidden;

  > .react-pdf__Page {
    min-width: auto !important;

    canvas {
      width: auto !important;
      height: auto !important;
      max-width: 100%;
    }
  }
`
