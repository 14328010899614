import { useMemo, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useGetTermsAndConditions } from 'services/terms-and-conditions/useTermsAndConditions'
import { handleSelectTerm } from 'utils'
import { Page, pdfjs } from 'react-pdf'
import { useAppInfo } from 'hooks/useAppInfo'
import { IconTheme } from 'components/IconTheme'
import terms from 'services/terms-and-conditions/data'
import { compare } from 'compare-versions'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import { Text } from '@interco/inter-ui/components/Text'
import { Flex, FlexRow } from '@interco/inter-ui/components/Flex'

import 'react-pdf/dist/esm/Page/TextLayer.css'

import * as S from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export function TermsAndConditionsViewer() {
  const navigate = useNavigate()
  const { id, isWebview } = useParams<{ id: string; isWebview?: string }>()
  const { appVersion, language } = useAppInfo()

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const { data: termsResponse } = useGetTermsAndConditions()
  const data =
    appVersion && compare(String(appVersion), '13.4', '>=') ? termsResponse : terms[language]

  const term = useMemo(() => !!data && data.find((item) => item.id === id), [data, id])

  const NativeTerms = () => {
    !!term && handleSelectTerm(term)
    return <></>
  }
  const WebviewTerms = () => {
    if (!term) return null

    const { title, url } = term

    const handleBackButton = () => {
      isWebview === 'is-webview'
        ? navigate(`/terms-and-conditions`)
        : interWbNavigate.requestGoBack()
    }

    const handleDownload = () => interWbNavigate.openNativeBrowser(url)

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: { numPages: number }) => {
      setNumPages(nextNumPages)
    }

    const handleClickLess = () => {
      setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)
    }

    const handleClickMore = () => {
      setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)
    }

    return (
      <>
        <AppBar
          leftIcon={
            <IconTheme
              icon="orangeds/MD/arrow-left"
              width={24}
              height={24}
              onClick={handleBackButton}
            />
          }
          rightIcon={
            <IconTheme
              icon="orangeds/MD/download"
              width={24}
              height={24}
              onClick={handleDownload}
            />
          }
        >
          <Text variant="headline-h3" semiBold className="mx-2 text-center">
            {title}
          </Text>
        </AppBar>
        <S.Document
          file={url}
          error=""
          loading={
            <Flex alignItems="center" justifyContent="center">
              <Text variant="body-3" as="p">
                Carregando PDF...
              </Text>
            </Flex>
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            key={`page_${pageNumber + 1}`}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
          />
        </S.Document>
        <FlexRow
          className="absolute bottom-0 h-20 border-t border-solid border-inter-gray-200"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconTheme
            icon="orangeds/MD/chevron-left"
            width={48}
            height={48}
            onClick={handleClickLess}
            color={pageNumber === 1 ? 'var(--gray200)' : 'var(--primary500)'}
          />
          <Text variant="body-3" as="p" colorWeight={500}>
            Página {pageNumber}/{numPages}
          </Text>
          <IconTheme
            icon="orangeds/MD/chevron-right"
            width={48}
            height={48}
            onClick={handleClickMore}
            color={pageNumber === numPages ? 'var(--gray200)' : 'var(--primary500)'}
          />
        </FlexRow>
      </>
    )
  }
  return appVersion && compare(String(appVersion), '13.4', '>=') ? NativeTerms() : WebviewTerms()
}
