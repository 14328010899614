export enum AccountTypesEnum {
  ACCOUNT_CAYMAN = 'ACCOUNT_CAYMAN',
  ACCOUNT_BRAZIL = 'ACCOUNT_BRAZIL',
}

export type SetSettingsGaDebitCardProps = GaSettingsHeaders & {
  operation: string
  value: string
}

export type GaSettingsHeaders = {
  'x-inter-ia-security': string
  'x-inter-card-id': string
}

export type GetSettingsGaDebitCardProps = GaSettingsHeaders

export type GetSettingsGaDebitCardApiProps = {
  operation: string
  value: string
}
