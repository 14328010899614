import { GetAccountInfoApiProps } from 'services/account-info/types'

import { interWbHttp } from '@interco/inter-webview-bridge'

export const getAccountInfo = async (): Promise<GetAccountInfoApiProps> => {
  const endpoint = 'app-pf/international-account/bff/global-account/v1/screens/dados-bancarios'

  const { response } = await interWbHttp.get(endpoint)

  const data = JSON.parse(response)

  return data
}
