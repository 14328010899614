import { interWbHttp } from '@interco/inter-webview-bridge'

import {
  SetSettingsGaDebitCardProps,
  GetSettingsGaDebitCardProps,
  GetSettingsGaDebitCardApiProps,
} from './types'

export const getSettingsGaDebitCard = async (
  props: GetSettingsGaDebitCardProps,
): Promise<GetSettingsGaDebitCardApiProps[]> => {
  const endpoint = 'app-pf/international-account/card/v2/settings/list'

  const headers = {
    ...props,
  }

  const { response } = await interWbHttp.get(endpoint, headers)

  const data = JSON.parse(response)

  return data
}

export const setSettingsGaDebitCard = async (props: SetSettingsGaDebitCardProps): Promise<void> => {
  const endpoint = 'app-pf/international-account/card/v2/settings/list'

  const settings = []
  settings.push({
    operation: props.operation,
    value: props.value,
  })

  const headers = {
    'x-inter-ia-security': props['x-inter-ia-security'],
    'x-inter-card-id': props['x-inter-card-id'],
  }
  const body = { settings }

  await interWbHttp.post(endpoint, body, headers)
}
