import { IWbAppInfo } from '@interco/inter-webview-bridge'

export const appInfoMock: IWbAppInfo = {
  dimensions: {
    height: 1647,
    width: 1080,
  },
  appVersion: '13.1.1',
  isDarkMode: false,
  deviceType: 'SMARTPHONE',
  application: 'PF',
  organization: 'US',
  language: 'en-US',
  env: 'hmg',
  nomeTipo: 'hml',
  theme: 'dark',
  version: '13.1',
}
